import type { SerializedError } from "@reduxjs/toolkit";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import clsx from "clsx";
import { Loader } from "../loader";
import s from "./api-info.module.css";

type ErrorType = FetchBaseQueryError | SerializedError;
type ErrorData = {
  code: number;
  error?: string;
  errors?: string[];
};
type Props = {
  isLoading: boolean;
  isError: boolean;
  error?: ErrorType;
  loaderClassName?: string;
  errorClassName?: string;
  periodGetQuery?: any;
};

export const ApiInfo = (props: Props) => {
  const { isLoading, isError, error, loaderClassName, errorClassName, periodGetQuery } = props;

  if (isLoading) {
    return (
      <div className={clsx(s.loaderContainer, loaderClassName)}>
        <Loader />
      </div>
    );
  }

  if (isError) {
    let type = "Unknown";
    let message = "Something went wrong…";
    if (error && "name" in error) {
      type = error.name ?? type;
      message = error.message ?? message;
    }

    if (error && "data" in error && error.data) {
      const data = error.data as ErrorData;
      type = data?.code?.toString() ?? type;
      if (data.error) message = JSON.stringify(data.error || data.errors);
      if (data.errors) message = data.errors.join("\n");
    }
    let retro = ""
    if (periodGetQuery && periodGetQuery?.data?.periods?.retro_min && periodGetQuery?.data?.periods?.retro_max)
      retro = "Ретроспектива ленты от " + periodGetQuery?.data?.periods?.retro_min + " до " + periodGetQuery?.data?.periods?.retro_max
    if (periodGetQuery && periodGetQuery?.data?.periods?.retro_min && !periodGetQuery?.data?.periods?.retro_max)
      retro = "Ретроспектива ленты от " + periodGetQuery?.data?.periods?.retro_min
    if (periodGetQuery && !periodGetQuery?.data?.periods?.retro_min && periodGetQuery?.data?.periods?.retro_max)
      retro = "Ретроспектива ленты до " + periodGetQuery?.data?.periods?.retro_max
    return (
      <p className={clsx(s.errorContainer, errorClassName)}>
        <b>{type}</b>: {message}
        <br></br>
        {(type == '417' && periodGetQuery) && retro}
      </p>
    );
  }

  return null;
};
